import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Room, { loader as roomLoader } from "./room.js";

function App() {
    const user_name = document.cookie
        .split("; ")
        .find((row) => row.startsWith("name="))
        ?.split("=")[1];
    const room_name = document.cookie
        .split("; ")
        .find((row) => row.startsWith("room="))
        ?.split("=")[1];

    return <>
        <h1>Pax Centuria!</h1>
        <form method="POST" action="/api/room/submit">
            <div className="input">
                <label htmlFor="name">Name</label>
                <input id="name" name="name" type="text" defaultValue={user_name} />
            </div>

            <div className="input">
                <label htmlFor="room">Room</label>
                <input id="room" name="room" type="text" defaultValue={room_name} />
            </div>

            <button type="submit">Submit</button>
        </form>
    </>;
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
    },
    {
        path: "/room/:room_name",
        element: <Room />,
        loader: roomLoader
    }
]);

const container = document.getElementById("app");
const root = ReactDOM.createRoot(container)
root.render(<React.StrictMode>
    <RouterProvider router={router} />
</React.StrictMode>);