import { useParams, redirect, useLoaderData } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import init, { Sim } from 'sim-js';
import render from './render.ts'

await init();

export function loader() {
    const user_name = document.cookie
        .split("; ")
        .find((row) => row.startsWith("name="))
        ?.split("=")[1];
    console.log(user_name);
    if (!user_name) {
        return redirect("/");
    } else {
        return {
            user_name,
        }
    }
}

export default function Room() {
    const { user_name } = useLoaderData();
    const { room_name } = useParams();
    const [roomState, setRoomState] = useState({ type: 'DEFAULT' });

    const requestRef = useRef();
    const canvasRef = useRef();
    const simRef = useRef();

    const protocol = location.protocol.replace('http', 'ws');
    const ws_url = `${protocol}//${location.host}/api/room/${room_name}`;
    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
        ws_url,
        {
            share: false,
            shouldReconnect: () => true,
        },
    );

    // Run when the connection state (readyState) changes
    useEffect(() => {
        console.log("Connection state changed", readyState)
        if (readyState === ReadyState.OPEN) {
            sendJsonMessage({
                type: "Join",
                content: {
                    name: user_name
                },
            })
        }
    }, [readyState]);

    // Run when a new WebSocket message is received (lastJsonMessage)
    useEffect(() => {
        const msg = JSON.stringify(lastJsonMessage);
        console.log(msg, roomState);
        if (!lastJsonMessage) {
            return;
        } else if (lastJsonMessage.type === 'RoomJoined') {
            const content = lastJsonMessage.content;
            const users = [...content.existing, content.new];
            if (roomState.type === 'DEFAULT') {
                setRoomState({ type: 'STARTING', users });
            } else if (roomState.type === 'STARTING') {
                setRoomState({ type: 'STARTING', users })
            } else {
                console.error(`joined bad state: ${msg}`);
            }
        } else if (lastJsonMessage.type === 'RoomStart') {
            if (roomState.type === 'STARTING') {
                const t = performance.now();
                const users = roomState.users;
                const sim = new Sim(t, users);
                simRef.current = sim;
                setRoomState({ type: 'RUNNING' })
            } else {
                console.error(`start bad state: ${msg}`);
            }
        } else if (lastJsonMessage.type === 'UserEvent') {
            if (!!simRef.current) {
                simRef.current.handle_event(lastJsonMessage.content);
            }
        } else {
            console.error(`Unrecognized message type: ${msg}`)
        }
    }, [lastJsonMessage]);

    const onMouseDown = (event) => {
        console.log(simRef.current.to_json().frame, event);
        if (roomState.type === 'RUNNING') {
            sendJsonMessage({
                type: 'UserEvent',
                content: {
                    ty: {
                        type: 'KeyDown',
                        content: undefined,
                    }
                }
            })
        }
    }

    const onMouseUp = (event) => {
        console.log(simRef.current.to_json().frame, event);
        if (roomState.type === 'RUNNING') {
            sendJsonMessage({
                type: 'UserEvent',
                content: {
                    ty: {
                        type: 'KeyUp',
                        content: undefined,
                    }
                }
            })
        }
    }

    const animate = () => {
        const time = performance.now();
        if (simRef.current) {
            simRef.current.tick(time);
            const state = simRef.current.to_json();
            const canvas = canvasRef.current;
            render(state, canvas);

        }
        requestRef.current = requestAnimationFrame(animate);
    }

    useEffect(() => {
        requestRef.current = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(requestRef.current);
    }, []); // Make sure the effect runs only once

    let content = "";
    let canvasStyle = { display: "none" };
    if (roomState.type === 'RUNNING') {
        canvasStyle = {};
    } else {
        const startGame = () => {
            sendJsonMessage({
                type: "Start",
                content: null,
            });
        }

        content = <div>
            <div onClick={startGame}>START</div>
            {JSON.stringify(roomState, null, 2)}
        </div>;
    }

    return <>
        <h1>Room {room_name}</h1>
        <div>
            {content}
            <canvas
                ref={canvasRef}
                style={canvasStyle}
                width={720}
                height={720}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
            />
        </div>
    </>;
}
