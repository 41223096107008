import * as sim from './state.ts';

const COLORS = [
    "red",
    "blue",
    "green",
    "yellow",
]

const render = (state: sim.Sim, canvas: HTMLCanvasElement) => {
    const g = canvas.getContext("2d");
    if (g === null) {
        throw "Can't acquire context";
    }
    g.clearRect(0, 0, canvas.width, canvas.height);

    let i = 0;
    for (const player of state.players) {
        const factory = player.factory;

        g.save();

        g.translate(factory.translation[0], factory.translation[1]);
        g.rotate(factory.rotation);

        g.fillStyle = COLORS[i];
        g.fillRect(-25, -25, 50, 50);
        g.fillStyle = "black";
        g.fillText(`${factory.charge}`, 0., 0.);

        g.restore();

        for (const ship of player.ships) {
            g.save();

            g.translate(ship.translation[0], ship.translation[1]);
            g.rotate(ship.rotation);

            g.fillStyle = COLORS[i];
            g.fillRect(-10, -10, 20, 20);
            g.fillStyle = "black";
            g.fillText(`${ship.hp}`, 0., 0.);

            g.restore();
        }
        i++;
    }

    for (const bullet of state.bullets) {
        g.beginPath();
        const [x, y] = bullet.translation;
        g.arc(x, y, 10, 0, 2 * Math.PI);
        g.fill();
    }


    g.font = "48px serif";
    g.fillText(`Frame: ${state.frame}`, 10, 50);
    i = 1;
    for (const player of state.players) {
        i++;
        g.fillText(`${player.id}: ${player.name} ${player.state}`, 10, 50 * i);
    }
}

export default render;